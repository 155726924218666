export const getCrimes = `query getCrimes($query: CrimeQueryInput, $limit: Int!, $sortBy: CrimeSortByInput!){
  crimes(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    name
  }
}`

export const getCrime = `query getCrime($query: CrimeQueryInput){
  crime(query: $query) {
    _id
    name
    description
  }
}`


export const _getCrimes = `query CrimesData($input: CrimesDatum!){
  CrimesData(input: $input) {
    totalRecords
    crimes {
      _id
      name
      description
    }
  }
}`

export const addCrime = `mutation insertOneCrime($data: CrimeInsertInput!){
  insertOneCrime(data: $data) {
    _id
  }
}`

export const updateCrime = `mutation updateOneCrime($query: CrimeQueryInput!, $data: CrimeUpdateInput!) {
  updateOneCrime(query: $query, set: $data) {
    _id
  }
}`