<template lang="pug">
b-card
  validation-observer(ref="form", tag="form")
    b-form
      b-row
        // Crime
        b-col(md="6")
          b-form-group(
            :label="$t('label.crime')",
            :label-for="$t('label.crime')",
            description=""
          )
            validation-provider(:name="$t('label.name')", rules="required", v-slot="{ errors }")
              b-form-input(
                id="name",
                v-model="data.name",
                trim,
                :placeholder="$t('placeholder.name')"
              )
              small(class="text-danger") {{ errors[0] }}

      b-row
        // Description
        b-col(md="12")
          b-form-group(
            :label="$t('label.description')",
            :label-for="$t('label.description')",
            description=""
          )
            b-form-input(
              id="description",
              v-model="data.description",
              trim,
              :placeholder="$t('placeholder.crime_description')"
            )

      // Button
      b-row
        b-col(
          cols="12"
          class="text-right"
        )
          b-button(
            variant="primary",
            :disabled="isSubmitting",
            @click="validateAndSubmit"
          )
            | {{ $t('message.submit') }}

</template>

<script>
// eslint-disable-next-line import/extensions
import { ref, toRefs } from '@vue/composition-api/dist/vue-composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import vSelect from 'vue-select'

export default {
  name: 'Form',

  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },

  props: ['data', 'isSubmitting'],

  setup(props, { emit }) {
    const { showErrorMessage } = useNotifications()
    const form = ref(null)

    const validateCrime = () => new Promise((resolve, reject) => {
      form.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = () => {
      validateCrime()
      .then(() => emit('submit', toRefs(props.data)))
      .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    return {
      required,
      form,
      validateAndSubmit,
    }
  },
}
</script>

<style scoped>

</style>
